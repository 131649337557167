import React, { Suspense, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import theme from "../../../../Consts/Theme";
import heroBackground from "./Static/heroBackground.webp";
import heroTitleOne from "./Static/hero_title_one.svg";
import heroTitleTwo from "./Static/hero_title_two.svg";
import heroTitleThree from "./Static/hero_title_three.svg";
import academyYear from "./Static/academy_year.svg";
import { collegeGroupStatus } from "../../../AwardsPending/AwardsPending";
import FullGroup from "../../../AwardsPending/Blocks/FullGroup";
import { Text20 } from "../../../../Components/Text/Text";
import useMediaCustom from "../../../../Hooks/useMediaCustom";
import HeroCardNumbers from "./HeroCardNumbers";

// styled-components
const StyledContainer = styled.section`
  background: ${theme.black2};
  background-image: url(${heroBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 190px;
  height: 100vh;

  .ml-40 {
    margin-left: 40px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }

  .flex-align-center {
    display: flex;
    align-items: center;
  }
`;
const HeadingTitlePartOne = styled.div`
  padding-left: 100px;

  .blurred-img {
    background-image: url("/student-1-small.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: inline-block;
    width: 140px;
    height: 100px;
    border-radius: 50px;
    margin-left: 40px;
  }

  .blurred-img img {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .blurred-img::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 2.5s infinite;
    background-color: white;
    border-radius: 50px;
  }

  .blurred-img.loaded::before {
    animation: none;
    content: none;
  }
  .blurred-img.loaded {
    background-image: none;
  }
  .blurred-img.loaded img {
    opacity: 1;
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    img.img-title {
      width: 40%;
    }
  }
`;
const HeadingTitlePartTwo = styled.div`
  padding-left: 200px;
  width: 100%;

  .blurred-img {
    background-image: url("/student-2-small.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: inline-block;
    width: 180px;
    height: 110px;
    border-radius: 50px;
    margin-left: 40px;
  }

  .blurred-img img {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .blurred-img::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 2.5s infinite;
    background-color: white;
    border-radius: 50px;
  }

  .blurred-img.loaded::before {
    animation: none;
    content: none;
  }
  .blurred-img.loaded {
    background-image: none;
  }
  .blurred-img.loaded img {
    opacity: 1;
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    padding-left: 150px;
    img.img-title {
      width: 70%;
    }
  }
`;
const HeadingTitlePartThree = styled.div`
  padding-left: 50px;
  position: relative;

  .blurred-img {
    background-image: url("/student-3-small.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: inline-block;
    width: 180px;
    height: 110px;
    border-radius: 50px;

  }

  .blurred-img img {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .blurred-img::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 2.5s infinite;
    background-color: white;
    border-radius: 50px;
  }

  .blurred-img.loaded::before {
    animation: none;
    content: none;
  }
  .blurred-img.loaded {
    background-image: none;
  }
  .blurred-img.loaded img {
    opacity: 1;
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
    }
  }

  img.academy-year {
    position: absolute;
    right: 60px;
    bottom: 20px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .blurred-img {
      margin-left: 40px;
    }
    img.img-title {
      width: 30%;
    }

    img.academy-year {
      width: 30%;
      right: 30px;
      bottom: 30px;
    }
  }
`;
const StyledGroupStatusWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -35px;
  margin-bottom: 6px;

  div.group-status:first-child {
    margin-right: 8px;
  }

  @media (max-width: 992px) {
    margin-top: 30px;
    justify-content: center;
  }
`;
const StyledContentHeroBlock = styled.article`
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 11px;
  position: relative;
  margin-top: 90px;

  .content-mobile {
    display: none;
  }
  .go-to-test-button-container {
    display: none;
  }
`;

const StyledContent = styled.div`
  align-self: center;
  div.content-block-cta {
    line-height: 24px !important;
    display: inline-flex;
    padding-bottom: 1px;
    border-bottom: 1px solid ${theme.white};
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid ${theme.pink};
      color: ${theme.pink};
    }
  }

  .about-next-description {
    width: 640px;
    margin-bottom: 24px;
  }

  @media (max-width: 1024px) {
    .about-next-description {
      width: 100%;
    }
  }
`;

const HomepageHeroDesktop = ({ handleScrollToSection, landingNumbers }) => {
  const { t } = useTranslation();
  const blurredImageDivRef = useRef(null);
  const blurredImage2DivRef = useRef(null);
  const blurredImage3DivRef = useRef(null);

  const imgRef = useRef(null);
  const imgRef2 = useRef(null);
  const imgRef3 = useRef(null);

  useEffect(() => {
    const blurredImageDiv = blurredImageDivRef.current;
    const blurredImage2Div = blurredImage2DivRef.current;
    const blurredImage3Div = blurredImage3DivRef.current;

    const img = imgRef.current;
    const img2 = imgRef.current;
    const img3 = imgRef.current;

    const handleLoad = () => {
      blurredImageDiv.classList.add("loaded");
      blurredImage2Div.classList.add("loaded");
      blurredImage3Div.classList.add("loaded");
    };

    if (img.complete && img2.complete && img3.complete) {
      handleLoad();
    } else {
      img.addEventListener("load", handleLoad);
      img2.addEventListener("load", handleLoad);
      img3.addEventListener("load", handleLoad);

      blurredImageDiv.classList.remove("loaded");
      blurredImage2Div.classList.remove("loaded");
      blurredImage3Div.classList.remove("loaded");

      return () => {
        img.removeEventListener("load", handleLoad);
        img2.removeEventListener("load", handleLoad);
        img3.removeEventListener("load", handleLoad);
      };
    }
  }, []);

  return (
    <StyledContainer>
      <Wrapper>
        {/* --------------------------------- Main Heading ---------------------- */}

        <header>
          {/*  ------- Heading title part one ------- */}
          <HeadingTitlePartOne className="flex-align-center mb-8">
            <img
              src={heroTitleOne}
              alt="Heading Title"
              className="img-title"
              width="460"
              height="60"
              loading="lazy"
            />

            <div className="blurred-img" ref={blurredImageDivRef}>
              <img
                ref={imgRef}
                src="/student-1.png"
                alt="Student Image"
                className=""
                loading="lazy"
                width="140"
                height="100"
              />
            </div>
          </HeadingTitlePartOne>
          {/*  ------- Heading title part two ------- */}
          <HeadingTitlePartTwo className="flex-align-center mb-8">
            <div ref={blurredImage2DivRef}  className="blurred-img">
              <img
                src="/student-2.png"
                alt="Student Image"
                loading="lazy"
                ref={imgRef2}
                width="180"
                height="110"
              />
            </div>
            <img
              src={heroTitleTwo}
              alt="Heading Title"
              className="ml-40 img-title"
              width="757"
              height="61"
              loading="lazy"
            />
          </HeadingTitlePartTwo>
          {/*  ------- Heading title part three ------- */}
          <HeadingTitlePartThree className="flex-align-center">
            <img
              src={heroTitleThree}
              alt="Heading Title"
              className="img-title"
              width="460"
              height="60"
              loading="lazy"
            />
            <div ref={blurredImage3DivRef} className="blurred-img">
              <img
                src="/student-3.png"
                alt="Student Image"
                className="img-student "
                loading="lazy"
                ref={imgRef3}
                width="180"
                height="110"
              />
            </div>

            <img
              src={academyYear}
              alt=""
              className="academy-year"
              loading="lazy"
            />
          </HeadingTitlePartThree>
        </header>
        {/* ----------------------------- Filled Groups ---------------------------- */}

        {/* -------------------- content -------------------- */}

        <StyledContentHeroBlock>
          <StyledContent>
            <Text20
              className="about-next-description"
              color={theme.white}
              dangerouslySetInnerHTML={{
                __html: t(
                  `homepage.hero_section.content_about_next.description`
                ),
              }}
            ></Text20>

            <div className="content-block-cta" onClick={handleScrollToSection}>
              <Text20 color={theme.white} fontWeight={700}>
                {t("homepage.hero_section.content_about_next.cta_info_open")}
              </Text20>
            </div>
          </StyledContent>

          {/* ----------------------- cards numbers ---------------------- */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <StyledGroupStatusWrap>
              {collegeGroupStatus.desktop?.map((group) => {
                if (group.status === "full") {
                  return (
                    <div key={group.id} className="group-status">
                      <FullGroup
                        text={group.name}
                        border="1px solid #61626D"
                        color={theme.gray2}
                        bgColor="linear-gradient(104deg, #161616 0%, #2C2C31 104.95%)"
                      />
                    </div>
                  );
                }
              })}
            </StyledGroupStatusWrap>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "11px",
              }}
            >
              <HeroCardNumbers
                numbers={landingNumbers?.test_takers}
                content={t(
                  "homepage.hero_section.hero_card_mobile.test_takers"
                )}
                bgColor={theme.lightDark}
              />

              <HeroCardNumbers
                numbers={landingNumbers?.empty_seats}
                content={t(`homepage.hero_section.empty_seats_desktop`)}
                bgColor={theme.black3}
                preHeading={t("homepage.hero_section.available_groups")}
              />
            </div>
          </div>
        </StyledContentHeroBlock>
      </Wrapper>
    </StyledContainer>
  );
};

export default HomepageHeroDesktop;
