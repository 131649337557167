
import { useEffect, useState, useRef, useContext } from 'react';

//npm imports
import styled, { withTheme } from 'styled-components/macro';
import { API } from '../../Consts/Api';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

//component imports
import BrainsterNextLogo from '../../Components/BrainsterNextLogo/BrainsterNextLogo';
import { Caption12, Header25, Header32, HeaderLarge40, Subheader18, Text12, Text14, Text18 } from '../../Components/Text/Text';
import ButtonBasic from '../../Components/ButtonBasic/ButtonBasic';

//other imports
import { mediaBlogShape, nextBlogShape } from './Static/shapes.js';
import result_page_background from './Static/result_background.png';
import nextImage from './Static/next_platform_image.png';
import mediaImage from './Static/media_blog_image.png';
import result_content_background from './Static/result-content-background.png';
import { GlobalContext } from '../../Context/GlobalContext';
import coin_full from './Static/coin-full.png';
import coin_under from './Static/coin-under.png';
import coin_vertical from './Static/coin-vertical.png';
import Footer from '../../Components/Footer/Footer';

//styled components
const ResultWrapper = styled.main`
    min-height: 100vh;
    background-color: ${props => props.theme.violet};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px 00px 0;
    background-image: url(${result_page_background});
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    .mt-12 {
        margin-top: 12px;
    }
    &>section{
        width: 100%;
        height: max-content;
        min-height: 500px;
        padding: 40px 0 0 0;
        .brainster-logo-container{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 42px;
        }
        .brainster-header-container{
            padding-bottom: 154px;
            position: relative;
            &>img{
                position: absolute;
            }
            &>img:first-of-type{
                top: 3%;
                right: 10%;

                z-index: 0;
            }
    
            &>img:last-of-type{
                top: 85%;
                left: 1%;
                z-index: 1;
            }
        }

        ${Header32}, ${Text18}{
            text-align: center;
        }

        ${Header32}{
            width: 47%;
            margin: 0 auto 16px auto;  
            z-index: 3; 
            position: relative;
        }

        ${Text18}{
            margin-bottom: 40px;
        }

        .result-content-container{
            width: 100%;
            min-width: 515px;
            margin: 0 auto;
            background-color: ${props => props.theme.white};
            position: relative;
            &>${Text12}{
                display: none;
            }
            &>img{
                position: absolute;
                bottom:30%;
                right: 0;
            }
            .result-code-container{
                width: 86%;
                max-width: 1240px;
                height: 308px;
                background-image: url(${result_content_background});
                background-color: ${props => props.theme.violet};
                background-size: cover;
                background-repeat: repeat-y;
                transform: translateY(-154px);
                margin: 0 auto;
                border: 1px solid ${props => props.theme.black};
                border-radius: 154px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 2;
                p,h4{
                    text-align: center;
                }
                    ${Caption12}{
                        margin-bottom: 20px;
                    }
                    ${HeaderLarge40}{
                        margin-bottom: 16px;
                        text-decoration: underline;
                    }
                    ${Text14}{
                        margin-bottom: 12px;
                        max-width: 432px;
                        font-size: 12px;
                    }
                
            }
            .refer-friend-container{
                width: 42%;
                max-width: 612px;
                margin-top: 60px;
                border-bottom: 1px solid ${props => props.theme.veryLightGray};
                margin: 0 auto;
                padding-bottom: 64px;
                transform: ${props => props.isResult ? "" : "translateY(-94px)"};
                &>${Text14}{
                    margin-bottom: 20px;
                }
                .copy-link-input-containert{
                    width: 100%;
                    height: 64px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: ${props => props.theme.lightBlack};
                    border-radius: 4px;
                    padding: 0px 24px;
                    margin-bottom: 8px;
                    &>div{
                        width: 85%;
                        display: flex;
                        align-items: center;
                        svg{
                            width: 24px;
                            margin-right: 24px;
                        }
                        input{
                            font-family: 'Raleway', sans-serif;  
                            color: ${props => props.theme.white};
                            width: calc(100% - 48px);
                            background: unset;
                            border: none;
                            font-weight: 500;
                            &:focus, &:active, &:visited {
                                outline: none;
                                box-shadow: none;
                                border: none;
                            }
                        }
                    }
                    ${Text14}{
                        color: ${props => props.theme.yellow};
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .cta-container{
            width: 100%;
            background-color: ${props => props.theme.white};
            min-width: 515px;
            padding-top: 64px;
            &>div:first-of-type{
                display: block;
            }
            &>p{
                width: 85%;
                max-width: 1030px;
                margin: 0 auto 65px auto;
                text-align: center;
            }
            .blogs-container{
                width: 100%;
                display: flex;
                justify-content: center;
                gap: 15px;
                margin-top: 26px;
                padding-bottom: 180px;
            }
        }
    }
    @media (max-width: 1100px){
        &>section{

            .brainster-header-container{
                &>img:first-of-type{
                    right: 4%;
                }
            }
            ${Header32}{
                width: 66%;
            }
            .refer-friend-container{
                width: 90%!important;
            }

        .cta-container{
            .blogs-container{
                flex-direction: column;
                align-items: center;
                gap: 48px;
            }
        }
    }
}
    @media (max-width: 768px){
        padding: 30px 0 0px 0;
        &>section{
            .result-content-container, .cta-container{
                min-width: 420px;
            }
            ${Header32}{
                width: 85%;
                margin: 0 auto 16px auto;   
            }
            
            ${Subheader18}{
                margin-bottom: 40px;
            }
        }
    }
    @media (max-width: 550px){
        padding: 0px;
        &>section{
            width: 100%;
            padding-top: 0px;
            background-image: none;
            .brainster-header-container{
                &>img{
                    display: none;
                }
                padding-top: 59px;
                padding-bottom: ${props => props.isResult ? "194px" : "0px"};
                display: flex;
                flex-direction: column;
                align-items: center;
                .brainster-logo-container{
                    margin-bottom: 32px;
                    svg{
                        path{
                            fill: ${props => props.theme.white};
                        }
                    }
                }
                ${Header32}{
                    font-size: 25px;
                    line-height: 30px;
                    font-weight: 500;
                    width: 80%;
                    margin: 50px auto 24px auto;
                }
                ${Text18}{
                    width: 80%;
                    margin-bottom: 0;
                }
                ${Header32}, ${Subheader18}{
                    color: ${props => props.theme.white};
                }
            }
            .result-content-container{
                min-width: 290px;
                margin-top: -41px;
                &>${Text12}{
                    width: 90%;
                    margin: 0 auto;
                    text-align: center;
                    line-height: 18px;
                    padding-bottom: 40px;
                    border-bottom: 1px solid ${props => props.theme.veryLightGray};
                    display: ${props => props.isResult ? "" : "block"};
                    transform: translateY(-64px);
                }
                &>img{
                    display: none;
                }
                .result-code-container{
                    width: 100%;
                    border-radius: 55px;
                    padding-left: 20px;
                    padding-right: 20px;
                    /* height: 206px; */
                    transform: translateY(-94px);
                    &>p:last-of-type{
                        /* display: none; */
                    }
                }
                .refer-friend-container{
                    padding-bottom: 42px;
                    border-bottom: unset;
                    margin-bottom: 0px;
                    &>${Text14}{
                        margin-bottom: 14px;
                    }
                    .copy-link-input-containert{
                        padding: 0 18px;
                        &>div{
                            svg{
                                margin-right: 18px;
                            }
                        }
                    }
                }
                .refer-friend-container{
                    margin-top: ${props => props.isResult ? "-30px" : "72px"};
                }
            }
            .cta-container{
                min-width: 290px;
                padding: 0px 0px 56px 0px;
                position: relative;
                z-index: 9;
                &>div:first-child{
                    display: block;
                    width: 90%;
                    height: 1px;
                    background-color: ${props => props.theme.veryLightGray};
                    margin: 0px auto 42px auto;
                }
                &>${Header25}{
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 500;
                }
                .blogs-container{
                    width: 100%;
                    margin: 45px 0 0 0;
                    padding-bottom: 45px;
                    flex-direction: column;
                }
            }
        }
    }
    @media (max-width: 350px){

        .result-code-container{
            background-size: contain;
            background-position: right;
        }
    }
`
export const BlogCta = styled.div`
    width: 480px;
    height: 180px;
    border-radius: 90px;
    position: relative;
    background-color: ${props => props.theme.violet};
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid ${props => props.theme.black};
    ${Header25}{
        max-width: 164px;
        font-weight: 600;
    }
    &>p{
        z-index: 2;
        position: relative;
    }
    &>svg{
        z-index: 1;
    }
    img{
        position: absolute;
        z-index: 999999;
    }
    &.brainster_blog{
        img{
            top: 20px;
            right: 20px;
        }
        &>svg{
            position: absolute;
            top: -30px;
            left: 75%;
        }
        ${Header25}{
            position: absolute;
            top: 50%;
            left: 10%;
            transform: translateY(-50%);
        }
    }
    
    .circle{
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        aspect-ratio: 1 / 1;
        background-color: ${props => props.theme.altBlue};
        border-radius: 50%;
        transition: right .1s linear, left .1s linear;
    }
    &:hover{
        .circle{
            left: 0%;
        }

        &.media_blog{
            .circle{
                right: 0%;
            }
        }
    }
    &.media_blog{
        .circle{
            left: unset;
            right: 50%;
            
        }
        img{
            bottom: -20px;
            left: 30%;
        }

        &>svg{
            position: absolute;
            top: unset;
            bottom: -25px;
            left: 20%;
        }
        ${Header25}{
            position: absolute;
            top: 50%;
            right: 10%;
            transform: translateY(-50%);
        }
    }
    @media (max-width: 550px){
        width: 317px;
        height: 130px;
        .block-mobile-shape{
            display: block;
        }
        ${Header25}{
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
        }
        &>img{
            height: 90%;
        }

        &.media_blog{
            &>img{
                height: 140%;
            }
            &.block-shape{
                display: none;
            }
        }
    }
`

//main component
const Result = (props) => {
    const { t } = useTranslation();
    const { formatResultsDate, setDuration } = useContext(GlobalContext)
    const [result, setResult] = useState(false);
    const referFriendInputRef = useRef();
    const [isLinkCopied, setIsLinkCopied] = useState(false);

    const ctaBlogs = [
        {
            id: 1,
            link: API.createExternalLink("nextPlatform"),
            title: t("result.cta.brainster_blog"),
            color: props.theme.white,
            class: "brainster_blog",
            shape: nextBlogShape,
            image: nextImage
        },
        {
            id: 2,
            link: API.createExternalLink("nextMedia"),
            title: t("result.cta.media_blog"),
            color: props.theme.white,
            class: "media_blog",
            shape: mediaBlogShape,
            image: mediaImage
        },


    ]

    let params = useParams();
    useEffect(() => {
        API.axios.get(API.createApiRoute("result", { GUID: params.guid }))
            .then(response => response.data.success && setResult(response.data.data))
            .catch(error => console.error(error.response))
        setDuration(false)
        //eslint-disable-next-line
    }, [])

    const copyLinkToClipboard = (e) => {
        referFriendInputRef.current.select();
        document.execCommand('copy');
        referFriendInputRef.current.blur();
        setIsLinkCopied(true)
    }

    return (
        <ResultWrapper isResult={result}>
            <section>
                <div className="brainster-header-container">
                    <img src={coin_full} alt="coin" />
                    <img src={coin_under} alt="coin" />

                    <div className='brainster-logo-container'>
                        <Link to={API.createRoute("home")}>
                            <BrainsterNextLogo color={props.theme.white} />
                        </Link>
                    </div>
                    {result && <Header32 color={props.theme.white}>{result.completed ? t("result.main.completed.good_luck") : t("result.main.failed.header")}</Header32>}
                    {result && <Text18 color={props.theme.white}>{result.completed ? t("result.main.completed-with-results.description").replace("{PERCENT}", result.score) : t("result.main.failed.description")}</Text18>}
                </div>
                <div className="result-content-container">

                    <img src={coin_vertical} alt="coin" />
                    {result && result.completed &&
                        <div className="result-code-container">
                            <Caption12 color={props.theme.white}>{t("result.main.completed-with-results.your_code")}</Caption12>
                            <HeaderLarge40 color={props.theme.white}>{result.token}</HeaderLarge40>
                            <Text14 color={props.theme.white} fontWeight={500} >{t("result.main.completed-with-results.winners_date").replace("{EXPIRATION_DATE}", () => formatResultsDate()).replace("{DOMAIN}", `${process.env.REACT_APP_DOMAIN_URL}${API.createRoute("awards").replace("{ACADEMY_ID}", "").replace("/", "")}`)}</Text14>
                            <Link to={API.createRoute("awards", { ACADEMY_ID: 1 })}>
                                <ButtonBasic filling={"black"} variation={"large"} text={t("general.results")} />
                            </Link>
                        
                            <Text14 className='mt-12' color={props.theme.white} fontWeight={500} >{t("result.main.completed-with-results.results_in_email")}</Text14>

                        </div>
                    }
                    <Text12>{t("result.main.completed-with-results.results_in_email")}</Text12>
                    {result &&
                        <>
                            <div className="refer-friend-container">
                                <Text14 fontWeight={700}>{t("result.refer_a_friend.header")}</Text14>
                                <div className="copy-link-input-containert">
                                    <div>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z" fill="#8B8B8B" />
                                        </svg>
                                        <input ref={referFriendInputRef} type="text" readOnly={true} value={`${process.env.REACT_APP_DOMAIN_URL}reffer/${params.guid}`} />
                                    </div>
                                    <Text14 fontWeight={700} onClick={(e) => copyLinkToClipboard(e)}>{!isLinkCopied ? t("general.copy") : t("general.copied")}</Text14>
                                </div>
                                <Text12>{t("result.refer_a_friend.description")}</Text12>
                            </div>
                        </>
                    }
                </div>
                <div className="cta-container">
                    <div></div>
                    <Header25 color={props.theme.altBlack} fontWeight={600}>{t("result.cta.header")}</Header25>
                    <div className="blogs-container">
                        {ctaBlogs.length > 0 && ctaBlogs.map(blog => (
                            <a key={blog.id} href={blog.link} target="_blank" rel="noreferrer">
                                <BlogCta className={`${blog.class} blog`} background={blog.background}>
                                    <Header25 fontWeight={700} color={blog.color}>{blog.title}</Header25>
                                    <img src={blog.image} className='block-shape' alt="shape" />
                                    {blog.shape}
                                    <div className="circle"></div>
                                </BlogCta>
                            </a>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </ResultWrapper>
    )
}

export default withTheme(Result);